<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />

     <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <AMonthPicker
          v-model:value="state.params.month"
          format="MMMM YYYY"
          style="width:100%;"
          placeholder="Pilih Bulan"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterRegional 
          style="width:100%;"
          v-can:hide.distributor
          v-model:value="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterProvince
          style="width:100%;"
          v-can:hide.distributor
          v-model:region="state.params.region"
          v-model:value="state.params.provinsi"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterArea
          style="width:100%;"
          v-can:hide.distributor
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region"
          v-model:value="state.params.area"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterKabupatenKota
          style="width:100%;"
          v-can:hide.distributor
          v-model:region="state.params.region"
          v-model:provinsi="state.params.provinsi"
          v-model:area="state.params.area"
          v-model:value="state.params.kabupaten"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterBrand
          style="width:100%;"
          v-model:value="state.params.brand"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterDistributor
          style="width:100%;"
          v-can:hide.distributor
          v-model:distributor="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"
          v-model:value="state.params.distributor"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <AButton
            type="primary"
            title="cari"
            @click="fetchDataList"
            :loading="state.isFetching">
            <span
                v-if="!state.isFetching"
                class="fa fa-search"
                aria-hidden="true"/>
            <span v-else>
                Memuat Data ...
            </span>
        </AButton>
      </ACol>
    </ARow>
    
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="Laporan-Detail-Transaksi-Jaringan-LT"/>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <md-table
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: state.meta.total,
                  pageSize: state.meta.per_page,
                  current: state.meta.page,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="state.isFetching"
              >
                <template #no="{ index }">
                  <span>
                    {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                  </span>
                </template>
                <template #nilai="{ text }">
                  <span>{{ text ? new Intl.NumberFormat("id-ID").format(text) : 0 }}</span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterBrand from '@/components/filter/FilterBrand'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterBrand,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref()
    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal Transaksi',
          dataIndex: 'tanggal',
        },
        {
          title: 'ID Toko',
          dataIndex: 'toko_code',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'toko_name',
        },
        {
          title: 'Alamat Toko',
          dataIndex: 'toko_address',
        },
        {
          title: 'Kabupaten Toko',
          dataIndex: 'toko_kab',
        },
        {
          title: 'Provinsi Toko',
          dataIndex: 'toko_prov',
        },
        {
          title: 'Regional Toko',
          dataIndex: 'toko_reg',
        },
        {
          title: 'Area Toko',
          dataIndex: 'toko_area',
        },
        {
          title: 'Brands ',
          dataIndex: 'brand_name',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'product_code',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'Harga ',
          dataIndex: 'price',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Zak Quantity',
          dataIndex: 'quantity_zak',
        },
        {
          title: 'UOM 1 ',
          dataIndex: 'uom1',
        },
        {
          title: 'TON Quantity',
          dataIndex: 'quantity_ton',
        },
        {
          title: 'UOM 2',
          dataIndex: 'uom2',
        },
        {
          title: 'No Transaksi',
          dataIndex: 'ts_id',
        },

        {
          title: 'Kode Distributor',
          dataIndex: 'distributor_code',
        },
          {
          title: 'Nama Distributor',
          dataIndex: 'distributor_name',
        },
        {
          title: 'Kode LT',
          dataIndex: 'lt_code',
        },
        {
          title: 'Nama LT',
          dataIndex: 'lt_name',
        },
        {
          title: 'Regional LT',
          dataIndex: 'lt_reg',
        },
        {
          title: 'Provinsi LT',
          dataIndex: 'lt_prov',
        },
        {
          title: 'Area LT',
          dataIndex: 'lt_area',
        },
        {
          title: 'Kabupaten LT',
          dataIndex: 'lt_kab',
        },
        {
          title: 'Cluster Pareto',
          dataIndex: 'cluster_name',
        },
        {
          title: 'SSM',
          dataIndex: 'user_ssm',
          title_xls: 'SSM',
        },
        {
          title: 'ASM',
          dataIndex: 'user_sm',
          title_xls: 'ASM',
        },
        {
          title: 'TSO',
          dataIndex: 'user_am',
          title_xls: 'TSO',
        },
      ],
      endpoint: '/api/report/detail-trx-lt-v2/toko',
      data: [],
      isFetching: false,
      isDownloading: false,
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        brand: [],
        distributor: [],
        month: moment(new Date()).startOf('month').format('YYYY-MM'),
        page: 1,
        'per-page': 10,
      }),
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchDataList()
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.month) {
        state.params.month = moment(state.params.month).format('YYYY-MM')
      }

      return updateUrlWithQuery(params)
    }

    const fetchDataList = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data
          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchDataList()
    })

    return {
      state,
      handleTableChange,
      fetchDataList,
      queryParams,
      errorMessage,
    }
  },
})
</script>
